import axios from 'axios'
import store from '../store'
import router from '../router'
// import { getUrlParam } from '../utils/common'



// 用于把数据转换为form形式
const parseObjectType = (o) => Object.prototype.toString.call(o).slice(8, -1);
// 用于把数据转换为form形式
const stringifyForm = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach(item => {
        const value = data[item];
        if (parseObjectType(value) === 'File') {
            formData.append(item, value, value.name)
            return;
        }
        formData.append(item, data[item])
    });
    return formData
};

// 创建axios实例
const service = axios.create({
    baseURL: '/api',
    timeout: 50000
})



// request拦截器
service.interceptors.request.use(config => {
    const { token } = store.getters
        // const token = store.getters.token
        // console.log("我是拦截器的"+token)
        // const token = 'c1e2nmMUjUrKzkUFA14p2Nqd1XxbFgA9V3qwE/GVG93tJFrmWjZ3d3x/F1COTj6KXdRJiHhTrbbFv8Idj/xJADVJXqcva13O31PDULjT'

    if (token) {
        // isWeixin ? config.headers['token'] = token : config.headers['dingding-token'] = token
        // console.log("我是service拦截器的 " + localStorage.getItem('dingding-token'))
        localStorage.getItem('dingding-token') ? config.headers['dingding-token'] = token : config.headers['token'] = token
    }

    return config

}, error => {
    // console.log('request err' + error)
    Promise.reject(error)
})

// 响应拦截器 
const duration = 1000 // 提示消息框持续时间
service.interceptors.response.use(
    response => {
        const res = response.data
        console.log("res是" + res.status)
        if (res.status === -100) {
            //没有登录需要跳转到小程序登录页面
            console.log("这个需要登录才能调用接口")
            console.log('interceptor err' + res.msg)
                // router.push('/login')
            return Promise.reject('未登录')
        }

        if (res.status === -1) {
            console.log('interceptor err' + res.msg)
            return Promise.reject(res)
        }
        if (res.status !== 1) {
            console.log('interceptor err' + res.msg)
            return Promise.reject(res.msg)
        }
        // if (res.status !== 200) {
        //     console.log('interceptor err' + res.msg)
        //     return Promise.reject('网络错误')
        // }
        // console.log('interceptor err' + res.msg)
        return res
    },
    error => {
        console.log('interceptor err' + error)
        return Promise.reject(error)
    }
)
export default service